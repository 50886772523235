import(/* webpackMode: "eager", webpackExports: ["NotFoundClientSide"] */ "/vercel/path0/apps/frontend/src/app/[locale]/sites/[siteDomain]/not-found.client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/@sanity+next-loader@1.3.1_@sanity+types@3.80.1_@types+react@19.0.10__next@15.2.3_@babel+core@_pcsgavxsup4uzdqpj27obn4jau/node_modules/@sanity/next-loader/dist/client-components/live-stream.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/@sanity+next-loader@1.3.1_@sanity+types@3.80.1_@types+react@19.0.10__next@15.2.3_@babel+core@_pcsgavxsup4uzdqpj27obn4jau/node_modules/@sanity/next-loader/dist/client-components/live.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/next-sanity@9.8.57_@emotion+is-prop-valid@1.3.1_@sanity+client@6.28.3_@sanity+icons@3.7.0_rea_533rsyfifpxm5tvqzboskoyyqq/node_modules/next-sanity/dist/visual-editing/client-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/common/src/isInsideAnIframe.ts");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/common/src/useIsIframeReady.ts");
